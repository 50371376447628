// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const keycloakConfig = { // dev
    url: 'https://accounts.crossing.pt/auth',
    realm: 'crossing',
    clientId: 'ad-network',
    adminRole: 'brandspot-admin',
    credentials: {
        secret: 'cf15d015-255d-4c5e-8d22-600e9cdaf22e'
    }
};

// const keycloakConfig = {
//     url: 'http://localhost:8080/auth/',
//     realm: 'Spotside',
//     clientId: 'ads',
// };

export const environment = {
    production: false,
    appVersion: '2.0',
    USERDATA_KEY: 'authf649fc9a5f55',
    isMockEnabled: false,
    api: 'https://api-dev.brandspot.media',
    api_central: 'https://api-dev.brandspot.media',
    auth_api: 'https://api-dev.brandspot.media',
    keycloak: keycloakConfig,
    project: 'brandspot_panel',
    websocket_server: 'wss://api-dev.mybuyon.com:442',
    mypos_payments_callback: 'https://api-dev.brandspot.media/payments/mypos',
    apiUrl: 'api',
    default_countries: [
        {
            uuid: 1,
            name: 'Portugal',
        },
        {
            uuid: 2,
            name: 'España'
        }
    ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
