<div class="table-responsive adn-table-responsive pb-12">
    <table class="table table-head-custom table-head-bg table-borderless table-vertical-center" [ngClass]="{'table-head-bg-white' : tHeadWhite}">
        <thead>
            <tr class="text-left text-uppercase rounded">
                <th *ngFor="let f of fields" [class]="f.class">
                    <span (click)="changeArrow(f.label)" class="cursor-pointer">
                        <span>{{ f.label | translate }}</span>
                        <span *ngIf="f.subLabel && f.subLabelValue && f.label !== 'Price'" class="text-muted font-size-xs">
                            ({{ f.subLabel | translate }}: {{ f.subLabelValue }})
                        </span>
                        <span *ngIf="f.subLabel && f.subLabelValue && f.label === 'Price' " class="text-muted font-size-xs">
                            ({{ f.subLabel | translate }}: {{ f.subLabelValue | number:'1.2-2' }}€)
                        </span>
                        <i *ngIf="f.showArrow && f.arrowTop" class="ml-1 mb-1 flaticon2-arrow-up icon-xs"></i>
                        <i *ngIf="f.showArrow && !f.arrowTop" class="ml-1 flaticon2-arrow-down icon-xs"></i>
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let v of values; let i = index">
                <td *ngFor="let f of fields" [class]="f.class">
                    <ng-container
                            *ngIf="f.type === 'templated'"
                            [ngTemplateOutlet]="f.template"
                            [ngTemplateOutletContext]="{values: v, p: i}"
                    ></ng-container>
                    <div *ngIf="f.type === 'text'">
                        {{ v[f.field] | translate }}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="(values && values.length === 0) && errorMsg" class="col-12 p-0">
        <ngb-alert class="d-block text-muted font-size-h6 text-center rounded"
                   [ngClass]="{'adn-padding' : !imgPath, 'px-10': imgPath}"
                   [dismissible]="false"
                   [type]="'white'">
            <div class="d-flex flex-column align-items-center">
                <img *ngIf="imgPath" class="adn-img-errorMsg mb-6" [src]="imgPath" [alt]="errorMsg">
                {{ errorMsg | translate: {x: projectName} }}
            </div>
        </ngb-alert>
    </div>
    <div *ngIf="(values && values.length === 0) && tableErrorMsg" class="col-12 d-flex flex-column align-items-center">
        <img class="py-4" [src]="tableErrorMsg.imgPath" [alt]="tableErrorMsg.title">
        <h3 class="text-primary font-weight-bolder mb-4" translate>{{ tableErrorMsg.title }}</h3>
        <span *ngIf="this.instance && this.instance.isActive"
              class="text-muted font-size-h6 adn-subTitle mb-8">
            {{ tableErrorMsg.subTitle | translate }}
        </span>
        <span *ngIf="this.instance && !this.instance.isActive"
              class="text-muted font-size-h6 adn-subTitle mb-8" translate>
            Your company is in the approval process. Please wait.
        </span>
        <app-generic-button *ngIf="tableErrorMsg.actionBtn === 'campaign' && this.instance && this.instance.isActive"
                            [class]="'btn-primary'" (click)="emitCreate()"
                            [label]="'Campaign'">
        </app-generic-button>
        <app-generic-button *ngIf="tableErrorMsg.actionBtn === 'display' && this.instance && this.instance.isActive"
                            [class]="'btn-primary'" (click)="emitCreate()"
                            [label]="'Display'">
        </app-generic-button>
    </div>
</div>
