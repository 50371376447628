import {Injectable, OnDestroy} from '@angular/core';
import {Observable, BehaviorSubject, Subscription} from 'rxjs';
import {UserModel} from '../_models/user.model';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {KeycloakService} from 'keycloak-angular';
import jwt_decode from 'jwt-decode';
import {error} from 'protractor';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    // private fields
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    // public fields
    currentUser$: Observable<UserModel>;
    isLoading$: Observable<boolean>;
    currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    isLoadingSubject: BehaviorSubject<boolean>;
    currentUser: UserModel = null;

    get currentUserValue(): UserModel {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserModel) {
        this.currentUserSubject.next(user);
    }

    constructor(
        private router: Router,
        private http: HttpClient,
        private keycloakServ: KeycloakService,
        private translateServ: TranslateService,
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<UserModel>(null);
        this.currentUser$ = this.currentUserSubject.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();
    }

    // public methods
    // login(email: string, password: string): Observable<UserModel> {
    //   return new Observable<any>();
    // }

    logout() {
        this.router.navigate(['/']).then( r => {
            this.keycloakServ.logout();
        });
    }

    getUserByToken() {
        this.isLoadingSubject.next(true);
        this.keycloakServ.getToken().then(
            (r) => {
                if (r) {
                    const payload: any = jwt_decode(r);
                    const user = new UserModel();
                    user.uuid = payload.sub;
                    user.firstname = payload.given_name;
                    user.lastname = payload.family_name;
                    user.name = payload.name;
                    user.email = payload.email;
                    user.language = payload.locale;
                    if (payload.locale === 'pt-PT' || !payload.locale) {
                        user.language = 'pt';
                    }
                    if (payload.resource_access[environment.keycloak.clientId]) {
                        user.role = payload.resource_access[environment.keycloak.clientId].roles.find(x => x === environment.keycloak.adminRole);
                    }
                    this.currentUserSubject.next(user);
                    this.currentUser = user;
                    this.isLoadingSubject.next(false);
                } else {
                    window.location.reload();
                }
            },
            (err) => {
                window.location.reload();
            });
    }

    getUser(): UserModel {
        return this.currentUser;
    }

    // need create new user then login
    // registration(user: UserModel): Observable<any> {
    //   this.isLoadingSubject.next(true);
    //   // return this.authHttpService.createUser(user).pipe(
    //   //   map(() => {
    //   //     this.isLoadingSubject.next(false);
    //   //   }),
    //   //   switchMap(() => this.login(user.email, user.password)),
    //   //   catchError((err) => {
    //   //     console.error('err', err);
    //   //     return of(undefined);
    //   //   }),
    //   //   finalize(() => this.isLoadingSubject.next(false))
    //   // );
    //   return new Observable<any>();
    // }

    // forgotPassword(email: string): Observable<boolean> {
    //   this.isLoadingSubject.next(true);
    //   // return this.authHttpService
    //   //   .forgotPassword(email)
    //   //   .pipe(finalize(() => this.isLoadingSubject.next(false)));
    //   return new Observable<any>();
    // }

    // private methods
    // private setAuthFromLocalStorage(auth: AuthModel): boolean {
    //   // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    //   if (auth && auth.accessToken) {
    //     localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
    //     return true;
    //   }
    //   return false;
    // }

    // private getAuthFromLocalStorage(): AuthModel {
    //   try {
    //     const authData = JSON.parse(
    //       localStorage.getItem(this.authLocalStorageToken)
    //     );
    //     return authData;
    //   } catch (error) {
    //     console.error(error);
    //     return undefined;
    //   }
    // }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
