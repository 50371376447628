import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-accept-invites',
    templateUrl: './accept-invites.component.html',
    styleUrls: ['./accept-invites.component.scss']
})
export class AcceptInvitesComponent implements OnInit {

    values = [];

    constructor(
        public dialogRef: MatDialogRef<AcceptInvitesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.values = data.invites;
    }

    ngOnInit(): void {
    }

    closeDialog() {
        this.dialogRef.close(true);
    }

}
