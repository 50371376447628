import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IsAuthorizedGuard} from './services/is-authorized.guard';

export const routes: Routes = [
    {
        path: 'error',
        loadChildren: () =>
            import('./pages/errors/errors.module').then((m) => m.ErrorsModule),
    },
    {
        path: '',
        canLoad: [IsAuthorizedGuard],
        loadChildren: () =>
            import('./pages/layout.module').then((m) => m.LayoutModule),
    },
    {path: '**', redirectTo: 'error'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
