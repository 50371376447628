import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class TemplateConfigs {
    projectName = 'Spotside';
    linkEditAccount = 'https://accounts.crossing.pt/auth/realms/crossing/account';
    borderRadious = '1.3rem';
    primaryColor = '#0032FF';
    primaryColorLight = '#c8d2ff';
    chartColorFirst = '#0032FF';
    chartColorSecond = '#00D2FC';
    chartColorThird = '#00DCAC';
    chartBorderRadious = 16;
    chartBorderRadiousMobile = 16;
    headerLogo = './customization/assets/logotype/logo-header-ads.png';
    headerLogoMobile = './customization/assets/logotype/logo-mobile-header-ads.png';
    loadingLogo = './customization/assets/logotype/logo-loading-ads.png';
    companyIcon = './customization/assets/logotype/symbol-logo-ads.svg';
    noCampaignFiles = '';
    noDisplayImage = '';
    projectTitle = 'Spotside Ads';
    discoverSliderContent = [
        {
            path: '../../../customization/assets/discover/image_1.jpg',
            type: 'image',
            title: 'The #1 Marketplace for Digital Out-of-Home',
            link: '',
            linkName: '',
            description: '',
        },
        {
            path: '../../../customization/assets/discover/image_2.jpg',
            type: 'image',
            title: 'Easily create content with canva',
            link: 'https://www.canva.com/',
            linkName: 'Go to Canva',
            description: 'Learn more about Canva',
        },
        {
            path: '../../../customization/assets/discover/image_3.jpg',
            type: 'image',
            title: 'Need video design or production services?',
            link: 'https://crossing.pt/',
            linkName: 'Get in touch',
            description: 'Access our network of content creators',
        },
        {
            path: '../../../customization/assets/discover/image_4.jpg',
            type: 'image',
            title: 'More shine with Spotside based products',
            link: 'https://store.spotside.com',
            linkName: 'See solutions',
            description: 'Spotside based solutions',
        }
    ];
}

export default TemplateConfigs;

