import {BusinessAreaModel} from './business-area.model';

export class InstanceModel {
    uuid: string;
    name: string;
    legalName: string;
    vat: string;
    businessSector: BusinessAreaModel;
    website?: string;
    email?: string;
    address?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    phone?: string;
    isActive?: boolean;
    userId?: string;
    returnPercentage?: number;
    equipments?: any[];
    campaigns?: any[];
    account?: any;
    clientUsers?: any[];
    referral?: string;
    referralProgram?: boolean;
    cashbackCampaign?: number;
    cashbackDisplay?: number;
    returnTax?: any;
    minBalance?: any;
    isMinBalanceLimited?: boolean;
    canAddEquipments?: boolean;

    constructor(model?: InstanceModel) {
        if (model) {
            this.uuid = model.uuid;
            this.name = model.name;
            this.legalName = model.legalName;
            this.vat = model.vat;
            this.businessSector = model.businessSector;
            this.website = model.website;
            this.email = model.email;
            this.address = model.address;
            this.postalCode = model.postalCode;
            this.city = model.city;
            this.country = model.country;
            this.phone = model.phone;
            this.userId = model.userId;
            this.returnPercentage = model.returnPercentage;
            this.isActive = model.isActive;
            this.clientUsers = model.clientUsers;
            this.referral = model.referral;
            this.referralProgram = model.referralProgram;
            this.cashbackCampaign = model.cashbackCampaign;
            this.cashbackDisplay = model.cashbackDisplay;
            this.returnTax = model.returnTax;
            this.isMinBalanceLimited = model.isMinBalanceLimited;
            this.canAddEquipments = model.canAddEquipments;
        }
    }

    getOwners() {
        if (this.clientUsers) {
            return this.clientUsers.filter(x => x.role.name === 'Owner');
        }
        return [];
    }
}
