import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserModel} from '../_models/user.model';
import {GenericService} from './generic.service';

@Injectable({
    providedIn: 'root'
})
export class UsersService extends GenericService<UserModel> {

    constructor(private http1: HttpClient) {
        super(http1);
        this.endpoint = 'users';
    }

    listInstUsers(clientId: string, params: any) {
        this.endpoint = `client/${clientId}/users`;
        return super.list(params);
    }

    deleteInstUsers(clientId: string, userId: string) {
        this.endpoint = `client/${clientId}/users`;
        return super.delete(userId);
    }

    updateUserRole(clientId: string, userId, obj: any) {
        this.endpoint = `client/${clientId}/users`;
        return super.update(userId, obj);
    }

}
