// Angular
import {Injectable} from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {KeycloakService, KeycloakAuthGuard} from 'keycloak-angular';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
    constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
        super(router, keycloakAngular);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!this.authenticated) {
                await this.keycloakAngular.login();
                resolve(false);
                return;
            }
            console.log('role restriction given at app-routing.module for this route', route.data.roles);
            console.log('User roles coming after login from keycloak :', this.roles);
            resolve(true);
            // const requiredRoles = route.data.roles;
            // let granted = true;
            // if (!requiredRoles || requiredRoles.length === 0) {
            //   granted = true;
            // } else {
            //   for (const requiredRole of requiredRoles) {
            //     if (this.roles.indexOf(requiredRole) > -1) {
            //       granted = true;
            //       break;
            //     }
            //   }
            // }

            // if(granted === false) {
            //   await this.router.navigate(['/auth/login']);
            // }
            // resolve(granted);

        });
    }
}
