import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialogRef} from '@angular/material/dialog';
import {InvitesService} from '../../../../services/invites.service';
import {BehaviorSubject, fromEvent, Observable, Subscription} from 'rxjs';
import {AccountCreationComponent} from '../account-creation.component';
import {SnackBarService} from '../../../../services/snack-bar.service';
import {InstancesService} from "../../../../services/instances.service";

@Component({
    selector: 'app-invite-accept',
    templateUrl: './invite-accept.component.html',
    styleUrls: ['./invite-accept.component.scss']
})
export class InviteAcceptComponent implements OnInit, OnChanges {

    @ViewChild('nameTemplate', {static: true}) nameTemplate: TemplateRef<any>;
    @ViewChild('companyTemplate', {static: true}) companyTemplate: TemplateRef<any>;
    @ViewChild('roleTemplate', {static: true}) roleTemplate: TemplateRef<any>;
    @ViewChild('statusTemplate', {static: true}) statusTemplate: TemplateRef<any>;
    @ViewChild('actionsTemplate', {static: true}) actionsTemplate: TemplateRef<any>;

    tableFields = [];

    @Input() values = [];
    values$ = new BehaviorSubject<any[]>([]);

    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private translate: TranslateService,
        private invitesService: InvitesService,
        public dialogRef: MatDialogRef<AccountCreationComponent>,
        private adnSnackBar: SnackBarService,
        private instancesService: InstancesService
    ) {
        this.onResized();
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
            this.onResized();
        });
    }

    ngOnInit(): void {
        this.tableFields = [
            {
                label: 'Sent by',
                field: 'inviterName',
                type: 'templated',
                class: 'big',
                template: this.nameTemplate
            },
            {
                label: 'Company',
                field: 'client.name',
                type: 'templated',
                class: 'big',
                template: this.companyTemplate
            },
            {
                label: 'Role',
                field: 'role',
                type: 'templated',
                class: 'small',
                template: this.roleTemplate
            },
            {
                label: 'Status',
                field: 'status',
                type: 'templated',
                class: 'small',
                template: this.statusTemplate
            },
            {
                label: '',
                field: null,
                type: 'templated',
                class: 'small-status',
                template: this.actionsTemplate
            }
        ];
    }

    declineInvite(uuid: string, i: number) {
        this.invitesService.reject(uuid).subscribe(
            s => {
                this.handle(uuid, i);
                this.adnSnackBar.openSnackBar('Invite was successfully rejected.', 'success');
            },
            r => {
                this.adnSnackBar.openSnackBar('Error rejecting invite.', 'danger');
            }
        );
    }

    handle(uuid, i) {
        if (this.values[i].uuid === uuid) {
            this.values.splice(i, 1);
        }
        if (this.values.length === 0) {
            this.dialogRef.close(true);
        }
        this.instancesService.instancesChange$.next(true);
    }

    acceptInvite(uuid: string, i: number) {
        this.invitesService.accept(uuid).subscribe(
            s => {
                this.handle(uuid, i);
                this.adnSnackBar.openSnackBar('Invite was successfully accepted.', 'success');
            },
            r => {
                this.adnSnackBar.openSnackBar('Error accepting invite.', 'danger');
            }
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('values')) {
            this.loading$.next(true);
            this.values$.next(changes.values.currentValue);
            this.loading$.next(false);
        }
    }

    onResized() {
        this.loading$.next(true);
        setTimeout(() => {
            if (window.innerWidth > 991) {
                this.tableFields = [
                    {
                        label: 'Sent by',
                        field: 'inviterName',
                        type: 'templated',
                        class: 'big',
                        template: this.nameTemplate
                    },
                    {
                        label: 'Company',
                        field: 'client.name',
                        type: 'templated',
                        class: 'big',
                        template: this.companyTemplate
                    },
                    {
                        label: 'Role',
                        field: 'role',
                        type: 'templated',
                        class: 'small',
                        template: this.roleTemplate
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        type: 'templated',
                        class: 'small',
                        template: this.statusTemplate
                    },
                    {
                        label: '',
                        field: null,
                        type: 'templated',
                        class: 'small-status',
                        template: this.actionsTemplate
                    }
                ];
                this.loading$.next(false);
            }
            if (window.innerWidth <= 991) {
                this.tableFields = [
                    {
                        label: 'Company',
                        field: 'client.name',
                        type: 'templated',
                        class: 'big',
                        template: this.companyTemplate
                    },
                    {
                        label: 'Role',
                        field: 'role',
                        type: 'templated',
                        class: 'small',
                        template: this.roleTemplate
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        type: 'templated',
                        class: 'small',
                        template: this.statusTemplate
                    },
                    {
                        label: '',
                        field: null,
                        type: 'templated',
                        class: 'small-status',
                        template: this.actionsTemplate
                    }
                ];
                this.loading$.next(false);
            }
            if (window.innerWidth <= 640) {
                this.tableFields = [
                    {
                        label: 'Company',
                        field: 'client.name',
                        type: 'templated',
                        class: 'big',
                        template: this.companyTemplate
                    },
                    {
                        label: '',
                        field: null,
                        type: 'templated',
                        class: 'small-status',
                        template: this.actionsTemplate
                    }
                ];
                this.loading$.next(false);
            }
            this.loading$.next(false);
        }, 700);
    }
}
