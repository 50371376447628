import {Injectable} from '@angular/core';
import {CanLoad, Route, UrlSegment} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class IsAuthorizedGuard implements CanLoad {

    constructor(
        private readonly keycloakService: KeycloakService,
    ) { }

    canLoad(route: Route, segments: UrlSegment[]) {
        return this.keycloakService.init({
            config: environment.keycloak,
            initOptions: {
                onLoad: 'login-required',
                checkLoginIframe: false,
            },
            enableBearerInterceptor: true
        });
    }
}
