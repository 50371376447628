import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
    selector: 'app-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit, OnDestroy {

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {}

    ngOnInit() {}

    get getIcon() {
        switch (this.data.snackType) {
            case 'success':
                return {type: this.data.snackType, icon: 'Code/Done-circle'};
            case 'danger':
                return {type: this.data.snackType, icon: 'Code/Error-circle'};
            case 'warning':
                return {type: this.data.snackType, icon: 'Code/Warning-2'};
            default:
                return null;
        }
    }

    closeSnackbar() {
        this.data.snackBar.dismiss();
    }

    ngOnDestroy(): void {
        this.data = null;
    }

}
