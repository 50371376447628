import {NgModule} from '@angular/core';
import {SplitPipe} from './split/split.pipe';
import {TruncatePipe} from './truncate/truncate.pipe';

@NgModule({
    declarations: [
        SplitPipe,
        TruncatePipe
    ],
    exports: [
        SplitPipe,
        TruncatePipe
    ]
})

export class PipesModule {
    static forRoot() {
        return {
            ngModule: PipesModule,
            providers: [],
        };
    }
}
