import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {InviteModel} from '../_models/invite.model';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InvitesService extends GenericService<InviteModel> {

    addPopupInvites$ = new BehaviorSubject<boolean>(false);

    constructor(private http1: HttpClient) {
        super(http1);
        this.endpoint = 'client';
        this.ACTIONS = {
            list: 'INVITES_LIST',
            add: 'INVITES_ADD',
            update: 'INVITES_UPDATE',
            delete: 'INVITES_DELETE',
            item: 'INVITES_ITEM'
        };
    }

    // @ts-ignore
    list = (params: {}) => {
        this.endpoint = `client/invites/to-accept`;
        return super.list(params);
    }
    // @ts-ignore
    add = (clientId: string, object: InviteModel) => {
        this.endpoint = `client/${clientId}/invite`;
        return super.add(object);
    }
    // @ts-ignore
    addBulk = (clientId: string, data: InviteModel[]) => {
        this.endpoint = `client/${clientId}/invite`;
        return super.addBulk({data});
    }
    accept = (inviteId: string) => {
        this.endpoint = `client/accept`;
        return super.update(inviteId, {});
    }
    reject = (inviteId: string) => {
        this.endpoint = `client/reject`;
        return super.update(inviteId, {});
    }
}
