import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BusinessAreaModel} from '../../../../_models/business-area.model';
import {map} from 'rxjs/operators';
import {APIResp} from '../../../../_models/api-resp.model';
import {BusinessSectorService} from '../../../../services/business-sector.service';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-company-info',
    templateUrl: './company-info.component.html',
    styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit, OnChanges {

    @Input() formGroup: FormGroup;
    @Input() bannerTitle: string;
    @Input() title: string;
    @Input() subtitle: string;
    lang = 'pt';

    businessAreas: BusinessAreaModel[] = [];
    businessAreas$: BehaviorSubject<BusinessAreaModel[]> = new BehaviorSubject<BusinessAreaModel[]>([]);

    constructor(
        private businessSectServ: BusinessSectorService,
        private translate: TranslateService,
    ) {
        this.lang = this.translate.currentLang;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.formGroup && changes.formGroup.currentValue) {
            this.setBusinessSectors();
        }
    }

    ngOnInit(): void {}

    setBusinessSectors() {
        this.businessSectServ
            .list({}).pipe(
            map((resp: APIResp<BusinessAreaModel>) => {
                const ret = [];
                resp.data.forEach(r => {
                    if (r.translatedNames && (r.translatedNames.pt || r.translatedNames.en || r.translatedNames.es)) {
                        ret.push({
                            uuid: r.uuid,
                            name: r.translatedNames[this.lang]
                        });
                    } else {
                        ret.push({
                            uuid: r.uuid,
                            name: r.name
                        });
                    }
                });
                return ret;
            }))
            .subscribe(r => {
                r.unshift({
                    uuid: '',
                    name: this.translate.instant('Select business area'),
                });
                this.businessAreas = r;
                this.businessAreas$.next(r);
            }
        );
    }

    catchOptionChange(event) {
        this.formGroup.controls.businessSector.setValue(event);
    }
}
