<button [disabled]="isInstanceActive === false" [ngClass]="'d-flex align-items-center btn font-weight-bolder rounded font-size-sm ' + class">
    <span class="svg-icon svg-icon-md svg-icon-white">
        <span [inlineSVG]="'./assets/media/svg/icons/Code/Plus.svg'" cacheSVG="true"
              class="svg-icon svg-icon-xl">
        </span>
    </span>
    {{ label | translate }}
</button>


