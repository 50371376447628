import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ClientInterceptorService implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const client = localStorage.getItem(`${environment.project}_active_instance`);
        if (client && request.url.indexOf('admin') === -1) {
            request = this.addClient(request, JSON.parse(client).uuid);
        }
        if (client && request.url.indexOf('confirm') > 0 && request.url.indexOf('admin') > 0) {
            request = this.addClient(request, JSON.parse(client).uuid);
        }
        return next.handle(request);
    }

    private addClient(request: HttpRequest<any>, client: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                'BrandSpot-Client': `${client}`
            }
        });
    }
}
