<mat-form-field class="mt-8" [ngClass]="{ 'adn-form-field' : !showWhite, 'adn-form-field-white': showWhite }"
                appearance="outline" *ngIf="(loading$ | async) && options.length > 0">
    <mat-select matInput
                class="rounded"
                #selectOpts
                [placeholder]="options[0].name | translate"
                [ngModel]="options[indexOpt]"
                (ngModelChange)="catchOption($event)">
        <mat-option *ngFor="let op of options" [value]="op">
            {{ op.translatedName ? op.translatedName : (op.name | translate) }}
        </mat-option>
    </mat-select>
</mat-form-field>
