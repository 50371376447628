import {Injectable} from '@angular/core';
import {InstanceModel} from '../_models/instance.model';
import {HttpClient} from '@angular/common/http';
import {GenericService} from './generic.service';
import {environment} from '../../environments/environment.prod';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InstancesService extends GenericService<InstanceModel> {

    instance$ = new BehaviorSubject<InstanceModel>(null);
    instance: InstanceModel = null;
    addPopup$ = new BehaviorSubject<boolean>(false);
    instancesChange$ = new BehaviorSubject<boolean>(false);

    constructor(private http1: HttpClient) {
        super(http1);
        this.endpoint = 'client';
        this.ACTIONS = {
            list: 'CLIENTS_LIST',
            add: 'CLIENTS_ADD',
            update: 'CLIENTS_UPDATE',
            delete: 'CLIENTS_DELETE',
            item: 'CLIENTS_ITEM'
        };
    }

    setActiveInstance(inst: InstanceModel) {
        localStorage.setItem(`${environment.project}_active_instance`, JSON.stringify(inst));
        this.instance = new InstanceModel(inst);
        this.instance$.next(inst);
    }

    async getActiveInstance() {
        const inst = await localStorage.getItem(`${environment.project}_active_instance`);
        if (inst) {
            this.instance = new InstanceModel(JSON.parse(inst));
            this.instance$.next(this.instance);
        }
    }

    getInstanceAdmin(userId?: string): any {
        if (!userId) {
            return this.instance.getOwners();
        }
        return !!this.instance.getOwners().find(x => x.userId === userId);
    }

    clearInstance() {
        localStorage.removeItem(`${environment.project}_active_instance`);
        this.instance$.next(null);
    }
}
