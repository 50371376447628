import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../pages/components/generic/snack-bar/snack-bar.component';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {

    messageText: string [];

    constructor(
        public snackBar: MatSnackBar,
    ) {
    }

    public openSnackBar(message, type, duration?, verticalPosition?, horizontalPosition?) {
        const _snackType = type !== undefined ? type : 'success';
        this.snackBar.openFromComponent(SnackBarComponent, {
            duration: duration || 5000,
            horizontalPosition: horizontalPosition || 'center',
            verticalPosition: verticalPosition || 'bottom',
            data: {message, snackType: _snackType, snackBar: this.snackBar}
        });
    }

}
