const keycloakConfig = {
    url: 'https://accounts.crossing.pt/auth',
    realm: 'crossing',
    clientId: 'ad-network',
    adminRole: 'brandspot-admin',
    credentials: {
        secret: 'cf15d015-255d-4c5e-8d22-600e9cdaf22e'
    }
};

export const environment = {
    production: true,
    appVersion: '2.0',
    USERDATA_KEY: 'authf649fc9a5f55',
    isMockEnabled: false,
    api: 'http://api.brandspot.media',
    api_central: 'http://api.brandspot.media',
    auth_api: 'http://api.brandspot.media',
    keycloak: keycloakConfig,
    project: 'brandspot_panel',
    websocket_server: '',
    mypos_payments_callback: 'https://api.brandspot.media/payments/mypos',
    apiUrl: 'api',
    default_countries: [
        {
            uuid: 1,
            name: 'Portugal',
        },
        {
            uuid: 2,
            name: 'España'
        }
    ]
};
