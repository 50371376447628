import { Injectable } from '@angular/core';
import {BusinessAreaModel} from '../_models/business-area.model';
import {HttpClient} from '@angular/common/http';
import {GenericService} from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessSectorService extends GenericService<BusinessAreaModel>{

  constructor(private http1: HttpClient) {
    super(http1);
    this.endpoint = 'business-sector';
    this.ACTIONS = {
      list: 'BUSINESS_SECTOR_LIST',
      add: 'BUSINESS_SECTOR_ADD',
      update: 'BUSINESS_SECTOR_UPDATE',
      delete: 'BUSINESS_SECTOR_DELETE',
      item: 'BUSINESS_SECTOR_ITEM'
    };
  }

}
