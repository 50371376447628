import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {SplashScreenService} from './splash-screen.service';
import TemplateConfigs from '../../../../../customization/template-configs';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
    @ViewChild('splashScreen', {static: true}) splashScreen: ElementRef;

    constructor(
        private el: ElementRef,
        private splashScreenService: SplashScreenService,
        private templateConfigs: TemplateConfigs,
    ) {
    }
    
    logo;

    ngOnInit(): void {
        this.splashScreenService.init(this.splashScreen);
        this.logo = this.templateConfigs.loadingLogo;
    }
}
