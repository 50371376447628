import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import KTWizard from '../../../../assets/js/components/wizard';
import {KTUtil} from '../../../../assets/js/components/util';
import {TranslateService} from '@ngx-translate/core';
import {InstanceModel} from '../../../_models/instance.model';
import {InstancesService} from '../../../services/instances.service';
import {AuthService} from '../../../services/auth.service';
import {UsersService} from '../../../services/users.service';
import {SnackBarService} from '../../../services/snack-bar.service';

@Component({
    selector: 'app-account-creation',
    templateUrl: './account-creation.component.html',
    styleUrls: ['./account-creation.component.scss']
})
export class AccountCreationComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('wizard', {static: true}) el: ElementRef;

    private subs: Subscription[] = [];
    model: InstanceModel;
    wizard = null;

    form = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.minLength(3)]),
        legalName: new FormControl('', [Validators.required, Validators.minLength(3)]),
        vat: new FormControl('', [Validators.required, Validators.minLength(9)]),
        businessSector: new FormControl('', [Validators.required, Validators.minLength(3)]),
        website: new FormControl(''),
        email: new FormControl(''),
        address: new FormControl(''),
        postalCode: new FormControl(''),
        city: new FormControl(''),
        country: new FormControl(''),
        phone: new FormControl('')
    });

    constructor(
        public dialogRef: MatDialogRef<AccountCreationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private instancesService: InstancesService,
        private adnSnackBar: SnackBarService,
        private translate: TranslateService,
        private authService: AuthService,
        private usersService: UsersService
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.wizard = new KTWizard(this.el.nativeElement, {
            startStep: 1
        });
        this.wizard.on('beforeNext', (wizardObj) => {
            const step = wizardObj.getStep();
            wizardObj.stop();
        });

        // Change event
        this.wizard.on('change', (wizard: any) => {
            setTimeout(() => {
                KTUtil.scrollTop();
            }, 500);
        });
    }

    prepareInstance() {
        if (!this.model) {
            this.model = new InstanceModel();
        }
        this.form.controls.name.setValue(this.form.controls.name.value.trim());
        this.form.controls.legalName.setValue(this.form.controls.name.value.trim());
        Object.keys(this.form.controls).forEach(key => {
            if (this.form.controls[key].value.length > 0 || typeof this.form.controls[key].value === 'object') {
                this.model[key] = this.form.controls[key].value;
            }
        });
    }

    clickNext() {
        this.prepareInstance();
        if (this.wizard.getStep() === 1) {
            if (this.form.valid) {
                if (!this.model.uuid) {
                    this.instancesService.add(this.model).subscribe(
                        r => {
                        this.model.uuid = r.uuid;
                        if (r.uuid) {
                            this.usersService.listInstUsers(r.uuid, {}).subscribe(res => {
                                r.clientUsers = res.data;
                                this.instancesService.setActiveInstance(r);
                            });
                        }
                        this.dialogRef.close(true);
                        this.adnSnackBar.openSnackBar('The company was successfully created.', 'success');
                    },
                        error => {
                            this.adnSnackBar.openSnackBar('Error creating company.', 'danger');
                        }
                    );
                } else {
                    this.instancesService.update(this.model.uuid, this.model).subscribe();
                    this.instancesService.setActiveInstance(this.model);
                }
            } else {
                setTimeout(this.wizard.goPrev, 10);
                this.adnSnackBar.openSnackBar('You must provide all the required information.', 'warning');
            }
        }
    }

    close() {
    }

    ngOnDestroy() {
        this.wizard = undefined;
        this.subs.forEach(sub => {
            sub.unsubscribe();
        });
    }

    closeDialog() {
        this.dialogRef.close(true);
    }

}
