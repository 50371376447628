<div>
    <app-loading-panel [show]="loading$ | async" [showFull]="true"></app-loading-panel>
    <div class="col-12 p-0 mt-4">
        <!--begin::Table-->
        <div class="table-responsive">
            <app-table-list
                    [fields]="tableFields"
                    [values]="values$ | async"
            ></app-table-list>

            <ng-template #nameTemplate let-values="values">
                <div class="d-flex align-items-center" style="min-width:200px;">
                    <div class="symbol symbol-50 flex-shrink-0 mr-4">
                        <app-user-avatar [name]="values.inviterName"></app-user-avatar>
                    </div>
                    <div>
                        <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                            {{ values.inviterName }}
                        </a>
                        <span class="text-muted font-weight-bold d-block">{{ values.inviterEmail }}</span>
                    </div>
                </div>
            </ng-template>
            <ng-template #companyTemplate let-values="values">
                <div style="min-width:150px;">
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ values.client.name }}</span>
                    <span class="text-muted font-weight-bold d-block">{{ values.client.legalName }}</span>
                </div>
            </ng-template>
            <ng-template #roleTemplate let-values="values">
                <span class="text-dark-75 font-weight-bolder d-block font-size-lg" *ngIf="values.role">{{ values.role.name | translate }}</span>
                <span class="text-muted font-weight-bold" *ngIf="values.inviteAccepted" translate>In Process</span>
                <span class="text-muted font-weight-bold" *ngIf="!values.inviteAccepted" translate>Pending</span>
            </ng-template>
            <ng-template #statusTemplate let-values="values">
                <span class="rounded label label-lg label-light-primary label-inline" *ngIf="!values.inviteAccepted" translate>Waiting acception</span>
                <span class="rounded label label-lg label-light-primary label-inline" *ngIf="values.inviteAccepted" translate>Active</span>
            </ng-template>
            <ng-template #actionsTemplate let-values="values" let-p="p">
                <a class="adn-invite-users-ico btn btn-icon btn-bg-light btn-icon-success
                        btn-hover-success cursor-pointer ml-4 pm" (click)="acceptInvite(values.uuid, p)">
                    <i class=flaticon2-check-mark></i>
                </a>
                <a class="btn btn-icon btn-bg-light btn-icon-danger btn-hover-danger cursor-pointer ml-2"
                   (click)="declineInvite(values.uuid, p)">
                        <span [inlineSVG]="'./assets/media/icons/Bold/Delete.svg'"
                              cacheSVG="true"
                              class="svg-icon svg-icon-lg"></span>
                </a>
            </ng-template>

        </div>
        <!--end::Table-->
    </div>
</div>
