import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {

  @Input() name = '';
  @Input() size = 25;
  @Input() fontSize = '2vh';

  constructor() { }

  ngOnInit(): void {
  }

}
