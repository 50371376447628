import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-loading-panel',
    templateUrl: './loading-panel.component.html',
    styleUrls: ['./loading-panel.component.scss']
})
export class LoadingPanelComponent implements OnInit {

    @Input() showFull: boolean;
    @Input() show: boolean;
    @Input() showVerticalFull: boolean;
    @Input() showVerticalSchedules: boolean;
    @Input() showVerticalAdvPrices: boolean;
    @Input() showRentedDisplays: boolean;
    @Input() showFileFolders: boolean;
    @Input() showCampaignInfoEdit: boolean;
    @Input() showSimple: boolean;
    @Input() showSimpleDiscover: boolean;
    @Input() showInDashboardCharts: boolean;
    @Input() showInDashboardChartsMini: boolean;
    @Input() showInEmptyPage: boolean;
    @Input() showImpressionsCampaign: boolean;
    @Input() showLoadingOnCampaign: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

}
