import {NgModule, DoBootstrap} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ClipboardModule} from 'ngx-clipboard';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {KeycloakService, KeycloakAngularModule} from 'keycloak-angular';
import {environment} from 'src/environments/environment';
// Highlight JS
import {HighlightModule, HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import {SplashScreenModule} from './_metronic/partials/layout/splash-screen/splash-screen.module';
import {ResponseInterceptorService} from './services/response-interceptor.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthService} from './services/auth.service';
import {AuthGuard} from './services/auth.guard';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FormsModule} from '@angular/forms';
import {MomentModule} from 'ngx-moment';
import {ClientInterceptorService} from './services/client-interceptor.service';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {PipesModule} from './pipes/pipe.module';
import {NgxUsefulSwiperModule} from 'ngx-useful-swiper';

const keycloakService = new KeycloakService();


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        FormsModule,
        KeycloakAngularModule,
        BrowserModule,
        BrowserAnimationsModule,
        SplashScreenModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        HighlightModule,
        ClipboardModule,
        AppRoutingModule,
        InlineSVGModule.forRoot(),
        NgbModule,
        MatDialogModule,
        MatSnackBarModule,
        FormsModule,
        MomentModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        PipesModule,
        NgxUsefulSwiperModule
    ],
    providers: [
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                coreLibraryLoader: () => import('highlight.js/lib/core'),
                languages: {
                    xml: () => import('highlight.js/lib/languages/xml'),
                    typescript: () => import('highlight.js/lib/languages/typescript'),
                    scss: () => import('highlight.js/lib/languages/scss'),
                    json: () => import('highlight.js/lib/languages/json')
                },
            },
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ClientInterceptorService,
            multi: true
        },
        {
            provide: KeycloakService,
            useValue: keycloakService
        },
        AuthService,
        AuthGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule implements DoBootstrap {
    async ngDoBootstrap(app) {
        const keycloakConfig = environment.keycloak;
        try {
            await keycloakService.init({
                config: keycloakConfig,
                initOptions: {
                    onLoad: 'login-required',
                    checkLoginIframe: false,
                },
                enableBearerInterceptor: true,
            });


            app.bootstrap(AppComponent);
        } catch (error) {
            console.error('Keycloak init failed', error);
        }
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
