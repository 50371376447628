import {Input} from '@angular/core';
import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-generic-button',
    templateUrl: './generic-button.component.html',
    styleUrls: ['./generic-button.component.scss'],
})
export class GenericButtonComponent implements OnInit {

    @Input() label: string;
    @Input() class: string;
    @Input() isInstanceActive: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

}
