import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {EventAction} from '../_models/event-action.model';
import {APIResp} from '../_models/api-resp.model';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GenericService<T> {
    public endpoint = '';
    private params = {
        page: 1,
        pageSize: 10,
        lang: 'pt'
    };

    public ACTIONS: any = {
        list: 'LIST',
        add: 'ADD',
        update: 'UPDATE',
        delete: 'DELETE',
        item: 'ITEM'
    };

    events$ = new BehaviorSubject<EventAction>(null);
    public host = environment.api;

    constructor(private http: HttpClient) {
    }

    list(params: any) {
        this.params = params;
        return this.http.get<APIResp<T>>(`${environment.api}/${this.endpoint}`, {params}).pipe(
            map((data: APIResp<T>) => {
                this.events$.next({
                    action: this.ACTIONS.list,
                    payload: data
                });
                return data;
            })
        );
    }

    blob() {
        const headers = new HttpHeaders();
        // @ts-ignore
        return this.http.get<Blob>(`${environment.api}/${this.endpoint}`, { headers, responseType: 'blob' });
    }

    add(object: T) {
        return this.http.post(`${environment.api}/${this.endpoint}`, object).pipe(
            map((data: T) => {
                this.events$.next({
                    action: this.ACTIONS.add,
                    payload: data
                });
                return data;
            })
        );
    }

    get(id: string) {
        return this.http.get(`${environment.api}/${this.endpoint}/${id}`).pipe(
            map((data: T) => {
                return data;
            })
        );
    }

    post(object: any) {
        return this.http.post(`${environment.api}/${this.endpoint}`, object).pipe(
            map((data: any) => {
                this.events$.next({
                    action: this.ACTIONS.add,
                    payload: data
                });
                return data;
            })
        );
    }

    patch(object: any, event = true) {
        return this.http.patch(`${environment.api}/${this.endpoint}`, object).pipe(
            map((data: any) => {
                if (event) {
                    this.events$.next({
                        action: this.ACTIONS.update,
                        payload: data
                    });
                }
                return data;
            })
        );
    }

    addBulk(body: any) {
        return this.http.post(`${environment.api}/${this.endpoint}`, body).pipe(
            map((data: T) => {
                this.events$.next({
                    action: this.ACTIONS.add,
                    payload: data
                });
                return data;
            })
        );
    }

    update(id: string, changes: any) {
        return this.http.patch(`${environment.api}/${this.endpoint}/${id}`, changes).pipe(
            map((data: T) => {
                this.events$.next({
                    action: this.ACTIONS.update,
                    payload: data
                });
                return data;
            })
        );
    }

    delete(id: string) {
        return this.http.delete(`${environment.api}/${this.endpoint}/${id}`).pipe(
            map((data: T) => {
                this.events$.next({
                    action: this.ACTIONS.delete,
                    payload: data
                });
                return data;
            })
        );
    }

    getSubArea<T2>(id: string, subarea: string, params: any) {
        return this.http.get(`${environment.api}/${this.endpoint}/${id}/${subarea}`, {params}).pipe(
            map((data: APIResp<T2>) => {
                return data;
            })
        );
    }

    addToSubArea<T2>(id: string, subarea: string, body: any, action: string) {
        return this.http.post(`${environment.api}/${this.endpoint}/${id}/${subarea}`, body).pipe(
            map((data: T2) => {
                this.events$.next({
                    action,
                    payload: data
                });
                return data;
            })
        );
    }

    updateInSubArea<T2>(id: string, subarea: string, sub_id: string, body: any, action: string) {
        return this.http.patch(`${environment.api}/${this.endpoint}/${id}/${subarea}${sub_id ? '/' + sub_id : ''}`, body).pipe(
            map((data: T2) => {
                this.events$.next({
                    action,
                    payload: data
                });
                return data;
            })
        );
    }

    deleteFromSubArea<T2>(id: string, subarea: string, sub_id: string, action: string) {
        return this.http.delete(`${environment.api}/${this.endpoint}/${id}/${subarea}/${sub_id}`).pipe(
            map((data: T2) => {
                this.events$.next({
                    action,
                    payload: data
                });
                return data;
            })
        );
    }
}
