<div *ngIf="getIcon">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <div class="svg-icon svg-icon-{{ getIcon.type }}">
                <span [inlineSVG]="'./assets/media/svg/icons/' + getIcon.icon + '.svg'"
                      cacheSVG="true"
                      class="svg-icon svg-icon-2x"></span>
            </div>
            <div class="ml-4 text-dark">
                <div class="d-flex flex-column">
                    <span *ngIf="getIcon.type === 'success'" class="font-weight-bold font-size-lg" translate>Success:</span>
                    <span *ngIf="getIcon.type === 'error'" class="font-weight-bold font-size-lg" translate>Error:</span>
                    <span *ngIf="getIcon.type === 'warning'" class="font-weight-bold font-size-lg" translate>Warning:</span>
                    <span translate>{{ data.message}}</span>
                </div>
            </div>
        </div>
        <div class="cursor-pointer">
            <i (click)="closeSnackbar()" class="flaticon2-cross font-size-sm text-{{ getIcon.type }}"></i>
        </div>
    </div>
</div>
